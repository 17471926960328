<template>
  <v-container fluid>
    <v-card>
      <v-toolbar>
        <v-btn
          icon
          class="hidden-xs-only"
          @click="$router.push({ name: 'RoleList' })"
        >
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('account.role-permission-relation') }} ({{ role | getText(role_items) | Translate }})</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        class="px-5 py-3"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        show-select
        v-model="selected"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          >
          </v-text-field>
        </template>
        <template v-slot:item.permission.viewset_name="{ item }">{{
          formatViewsetName(item.viewset_name)
        }}</template>
      </v-data-table>
      <v-col class="text-right">
        <v-btn color="primary" elevation="3" @click="back">{{
          $t("back")
        }}</v-btn>

        <v-btn color="primary" elevation="3" @click="update">{{
          $t("update")
        }}</v-btn>
      </v-col>
    </v-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import { role_items } from "@/definition.js";
export default {
  data: (vm) => ({
    url: "/account/role_permission_relations/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("viewset_name"),
        value: "viewset_name",
        width: "20%"
      },
      {
        sortable: true,
        text: vm.$i18n.t("action_name"),
        value: "action_name",
        width: "20%"
      },
      {
        sortable: false,
        text: vm.$i18n.t("description"),
        value: "description",
        width: "50%"
      }
    ],
    selected: [],
    search: "",
    role: null,
    role_items: role_items,
  }),
  mixins: [BaseTable],
  computed: {},
  methods: {
    formatViewsetName(value) {
      return value.replace('ViewSet', '');
    },
    back() {
      this.$router.back();
    },
    update() {
      let select_obj = JSON.stringify(this.selected);
      console.log("update() " + select_obj);
      let jsonData = {
        role: this.role,
        permissions: this.selected.map(item => item.id)
      };
      this.postApi(jsonData, this.url + "role_update/");
    },
    setResult(data) {
      let perms = data.map(perm => perm.permission.id);
      console.log("perms: " + perms);
      this.selected = this.items.filter(item => perms.includes(item.id));
    },
    fetchPermission() {
      this.getApi("/account/permissions/", this.setPermission, true);
    },
    setPermission(data) {
      this.totalItems = data.count;
      this.items = data;
    }
  },
  mounted() {
    this.fetchPermission();
    this.role = this.$route.query.role;
    this.getParams = {
      role: this.role,
      query: "{permission{id}}"
    };
  },
};
</script>
